<template>
    <div class="AddReturnDoc">
        <el-page-header @back="back" content="新增退货单 "></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <div class="banner">
                    {{ enterpriseStateInfo.enterpriseName }}
                </div>
                <div style="width: 99.9%;" class="boxShadow borderRa-10 paddingTB-20">
                    <div class="infoTable">
                        <div class="title">订单信息</div>
                        <div>
                            <div v-for="(item, index) of baseInfo" :key="index">
                                <div v-if="item.value" class="f-c-b titleItem">
                                    <span class="titval">{{ item.title }}</span>
                                    <div v-if="(item.title == '订单备注' || item.title == '订单操作备注') && item.value != '暂无信息'"
                                        class="remarks">
                                        {{ item.value }}
                                    </div>
                                    <span class="value" v-else>{{ item.value }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <el-form :model="orderDetailInfo" label-width="110px" class="AddReturn-order-form pb-30 f-w">
                        <div class="formTitle ">商品明细</div>
                        <div v-if="orderDetailInfo.orderDetailsList && orderDetailInfo.orderDetailsList.length">
                            <ModifiableProductDetailTable class="mb-30" slot="definedList"
                                :List="orderDetailInfo.orderDetailsList" :isShowResetBtn="true"
                                @changeNumberInput="changeNumberInput" @delectSelectProduct="delectSelectProduct"
                                @resetBtn="resetBtn" :orderType="'ReturnDoc'">
                            </ModifiableProductDetailTable>

                        </div>
                        <el-form-item class="w--50" v-for="(item, index) of addReturnDocForm" :label="item.label"
                            :prop="item.prop" :key="index">
                            <el-input v-if="item.label == '退货金额：'" v-model.trim="orderDetailInfo[item.prop]">
                            </el-input>
                            <el-input v-else-if="item.label == '运费金额：'" v-model.trim="orderDetailInfo[item.prop]">
                            </el-input>
                            <SelectOption v-else-if="item.label == '运费货币：'" :selectModel="orderDetailInfo[item.prop]"
                                :Option="CurrencyOption" :type="'FreightCurrency'"
                                @selectFreightCurrency="selectFreightCurrency">
                            </SelectOption>
                            <div class="col-red"
                                v-show="(orderDetailInfo.valuationCurrency != orderDetailInfo.freightCurrency) && item.label == '运费货币：'">
                                运费货币与退货单运费货币不一致
                            </div>
                        </el-form-item>
                        <div class="formTitle ">退货单据备注</div>
                        <TextArea class="fs-18" :modelValue="orderDetailInfo.returnNote" type="returnNote"
                            @getTextAreaValue="getTextAreaValue" maxlengthValue=" 300" :TextAreaisLimit="false"></TextArea>

                        <div class="formTitle mt-30">退货操作备注</div>
                        <TextArea class="fs-18" :modelValue="orderDetailInfo.retunModifyNote" type="retunModifyNote"
                            @getTextAreaValue="getTextAreaValue" maxlengthValue=" 300" :TextAreaisLimit="false"></TextArea>
                    </el-form>

                </div>
                <div class="f-c-c w--100 mb-40">
                    <div v-if="orderDetailInfo.orderStatus == 'Completed'" class="btn" @click="completedOrderReturn">发起退货
                    </div>
                    <div v-else class="btn" @click="undoneOrderReturn">发起退货</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TableColumn1 } from '@/utils/TableColumn'
import { CurrencyOption } from '@/utils/SelectOption'
export default {
    name: 'AddReturnDoc',
    components: {
        NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
    },
    data() {
        return {
            orderDetailInfo: {},
            baseInfo: [],
            TableColumn: TableColumn1,
            isShowResetBtn: false,
            CurrencyOption: CurrencyOption,
            addReturnDocForm: [{
                label: "退货金额：",
                prop: "realityAmount"
            }, {
                label: "运费货币：",
                prop: "freightCurrency"
            }, {
                label: "运费金额：",
                prop: "freightAmount"
            }
                // , {
                //     label: "退货单据备注：",
                //     prop: "returnNote",
                //     disabled: false
                // }, {
                //     label: "退货操作备注：",
                //     prop: "retunModifyNote",
                //     disabled: false
                // }
            ],
            enterpriseStateInfo: {}
        }
    },
    watch: {
        '$route.path': {
            handler(newVal) {
                let pageLayerBack = this.$getStorage('pageLayerBack');
                if (pageLayerBack.length == 2) {
                    pageLayerBack.push(newVal)
                    this.$setStorage('pageLayerBack', pageLayerBack)
                }
            },
            // 深度观察监听
            deep: true,
            immediate: true
        }
    },
    created() {
        this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo')
        let data = this.$getStorage('OrderDetailsUseReturnDoc'),
            { customerName, contact, contactPhone, customerCity, customerAddress, orderStatus,
                orderNumber, deliverDate, valuationCurrency, realityAmount2, freightCurrency, freightAmount,
                createDate, createName, orderNote, modifyNote, customerId } = data,
            orderStatusText = this.$orderClass.getOrderState(orderStatus),
            address = customerCity + ' ' + customerAddress;
        this.baseInfo = [{
            "title": "客户名称",
            "value": customerName
        }, {
            "title": "联系人",
            "value": contact
        }, {
            "title": "联系电话",
            "value": contactPhone
        }, {
            "title": "地址",
            "value": address
        }, {
            "title": "订单编号",
            "value": orderNumber
        }, {
            "title": "订单状态",
            "value": orderStatusText
        }, {
            "title": "发货时间",
            "value": deliverDate
        }, {
            "title": "成交金额",
            "value": valuationCurrency + ' ' + realityAmount2
        }, {
            "title": "运费",
            "value": freightCurrency + ' ' + (freightAmount ? freightAmount.toFixed(2) : freightAmount)
        }, {
            "title": "创建时间",
            "value": createDate
        }, {
            "title": "创建人",
            "value": createName
        }, {
            "title": "订单备注",
            "value": orderNote ? orderNote : '暂无信息'
        }, {
            "title": "订单操作备注",
            "value": modifyNote ? modifyNote : '暂无信息'
        }];
        data.orderDetailsList.forEach((item) => {
            item.originUnitPrice = item.unitPrice
            item.LastTransactionPrice = 0,
                item.valuationCurrency = ''
            item.visibleProductName = false;
            item.visibleProductNumber = false;
            item.visibleUnitPrice = false;
        })
        this.orderDetailInfo = data;
        this.orderDetailInfo.retunModifyNote = ''
        this.$CommonRequests.getEnterpriseOrderTemplate('RG').then((res) => {
            this.$set(this.orderDetailInfo, 'returnNote', res.data.enterpriseNote)
        })
        this.$setStorage('chooseCustomerId', customerId)
    },
    methods: {
        // 获取textarea内容
        getTextAreaValue(data) {
            this.orderDetailInfo[data.type] = data.value
        },
        back() {
            let pageLayerBack = this.$getStorage('pageLayerBack');
            pageLayerBack ? this.$setStorage('pageLayerBack', pageLayerBack.slice(0, -1)) : pageLayerBack
            this.$common.DelayedBack(0);
        },
        // 改变数组输入框
        changeNumberInput(currentValue, productId, type) {
            let OrderDetails = this.orderDetailInfo,
                list = OrderDetails.orderDetailsList,
                priseTotal = 0;
            list.forEach((item, index) => {
                if (item.productId == productId) {
                    // 改变产品价格
                    if (type == 'changeUnitPrice') {
                        list[index].amount = item.quantity * currentValue
                        list[index].auAmount = item.quantity * currentValue
                    }
                    //改变数量
                    if (type == 'changeQuantity') {
                        list[index].amount = item.unitPrice * currentValue
                        list[index].auAmount = item.unitPrice * currentValue
                    }
                    // 改变小计
                    if (type == 'changeRealAmount') {
                        list[index].amount = currentValue
                    }
                }
                priseTotal += list[index].amount
            })
            OrderDetails.realityAmount = priseTotal.toFixed(2);
            OrderDetails.orderDetailsList = list;
            this.orderDetailInfo = OrderDetails
        },
        // 删除选中的产品
        delectSelectProduct(productId) {
            let arr = this.orderDetailInfo.orderDetailsList.filter(item => item.productId != productId);
            let priseTotal = 0;
            arr.forEach((item, index) => {
                priseTotal += arr[index].amount
            })
            this.orderDetailInfo.realityAmount = priseTotal.toFixed(2);
            this.orderDetailInfo.orderDetailsList = arr;
        },
        // 重置按钮
        resetBtn() {
            let list = this.$getStorage('OrderDetailsUseReturnDoc').orderDetailsList,
                OrderDetailsUseReturnDoc = this.orderDetailInfo;
            OrderDetailsUseReturnDoc.orderDetailsList = list;
            OrderDetailsUseReturnDoc.realityAmount = this.$getStorage('OrderDetailsUseReturnDoc').realityAmount
        },
        // 选择运费货币
        selectFreightCurrency(value) {
            this.orderDetailInfo.freightCurrency = value;
        },
        // 获取参数
        getParam() {
            let that = this,
                { lastModifyDate, orderId, realityAmount, valuationCurrency, orderNote, realityAmount2,
                    freightCurrency, orderDetailsList, returnNote, retunModifyNote, freightAmount } = that.orderDetailInfo,
                ParmarOrderDetailsList = [];
            orderDetailsList.forEach((item) => {
                let detail = {
                    orderDetailsId: item.orderDetailsId,
                    amount: item.amount,
                    auAmount: item.auAmount,
                    productId: item.productId,
                    productName: item.productName,
                    customerUseName: item.customerUseName,
                    quantity: item.quantity,
                    unitPrice: item.unitPrice,
                    jrAmountFlag: '',
                    note: item.note,
                    productNumber: item.productNumber,
                    customerUseNumber: item.customerUseNumber
                };
                ParmarOrderDetailsList.push(detail)
            })
            return {
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                lastModifyDate,
                orderId,
                returnRealityAmount: Number(realityAmount),
                valuationCurrency,
                returnAutoAmount: Number(realityAmount2),
                freightAmount: Number(freightAmount),
                freightCurrency,
                orderNote,
                modifyNote: retunModifyNote,
                returnNote,//退货单备注
                orderDetailsList: ParmarOrderDetailsList,
            };
        },
        /* 未完成订单发起退货
        * 订单值为Deliver和Delivery请求未完成订单接口
        * 值为Completed调已完成接口
        */
        undoneOrderReturn() {
            let that = this,
                data = that.getParam();
            that.$confirm('是否发起退货？', '提示').then(() => {
                that.$http.undoneOrderReturn(data).then((res) => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$router.push({
                            path: '/OrderList',
                            query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: 'ReturnOrder',
                                    entry: 1
                                }))
                            }
                        })
                    } else {
                        that.$common.message(res.msg, 'warning', 1200)
                    }
                })
            }).catch(() => { })
        },
        // 已完成订单退货接口
        completedOrderReturn() {
            let that = this,
                data = that.getParam();
            that.$confirm('是否发起退货？', '提示').then(() => {
                that.$http.completedOrderReturn(data).then((res) => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$router.push({
                            path: '/OrderList',
                            query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: 'ReturnOrder',
                                    entry: 1
                                }))
                            }
                        })
                    }
                })
            }).catch(() => { })
        }

    },
    beforeDestroy() {
        this.$removeStorage('OrderDetailsUseReturnDoc')
        this.$removeStorage('chooseCustomerId')
    }
}

</script>
<style lang='less' scoped>
.AddReturnDoc {
    .main {
        width: 980px;

        .banner {
            background: url('../../../assets/supply/supplyBanner.png');
            height: 100px;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 100px;
            text-align: center;
            margin-bottom: 40px;
        }

        .infoTable {
            width: 899px;
            padding: 40px;
            border-radius: 10px;

            .title {
                margin-bottom: 22px;
                border-left: 4px solid #0363FA;
                padding-left: 10px;
                font-size: 24px;
                font-weight: 500;
                color: #333333;
            }

            .titleItem {
                padding: 0 30px;
                width: 840px;
                min-height: 50px;
                border: 1px solid #f2f2f2;
                margin-bottom: 5px;

                .titval {
                    font-size: 16px;
                    color: #999;
                    font-weight: 400;
                    width: 50%;
                }

                .value {
                    font-size: 16px;
                    color: #333;
                    font-weight: 400;
                    width: 50%;
                }

                .remarks {
                    width: 50%;
                    color: #333;
                    white-space: pre-line;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 25px;
                }
            }
        }

        .AddReturn-order-form {
            padding: 0 40px;

            .formTitle {
                width: 100%;
                margin-bottom: 22px;
                border-left: 4px solid #0363FA;
                padding-left: 10px;
                font-size: 24px;
                font-weight: 500;
                color: #333333;
            }

            .formTitle2 {
                // width: 100%;
                // margin-bottom: 22px;
                border-left: 4px solid #0363FA;
                padding-left: 10px;
                font-size: 24px;
                font-weight: 500;
                color: #333333;
            }

            .addCustomerBtn {
                width: 60px;
                height: 50px;
                background: url('../../../assets/supply/addCustomerBtn.png');
                cursor: pointer;
            }
        }

        .btn {
            margin: 30px 0;
            width: 160px;
            height: 50px;
            background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
            border-radius: 10px;
            color: #FFF;
            font-size: 16px;
            line-height: 50px;
            text-align: center;
            cursor: pointer;
        }
    }

    .add_Return_Doc_form {
        margin-top: 50px;
    }

    .add-btn {
        text-align: right;

        .add-customer1 {
            padding: 10px 20px;
            border-radius: 50px;
            margin-bottom: 10px;

        }
    }
}
</style>
